import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { PopoverComponent } from './components/popover/popover.component';
import { FirebaseModule } from './fb.module';
import { StateService } from './services/state.service';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [ConfirmationComponent, PopoverComponent, AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    /**
     * Local
     */
    FirebaseModule.forRoot(),
    AppRoutingModule,
    TranslocoRootModule,

    /**
     * Material
     */
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: 'MAIN_STATE',
      useExisting: StateService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
